const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6930689",
    space_js: "//sfzpgbz.gztechsw.com/production/to/openjs/nw_gun/common/q/resource/w.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6930690",
    space_js: "//sfzpgbz.gztechsw.com/production/lg_fo_y/resource/mfo_m.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6930691",
    space_js: "//sfzpgbz.gztechsw.com/source/m/hgp/openjs/zng-p/resource/q.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6930692",
    space_js: "//sfzpgbz.gztechsw.com/production/n/ih/qao/production/hq/source/x.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native2,
  Home_banner: Native3,
  Detail_inter: Inter,
  Detail_native: Native2,
  Detail_banner: Native3,
  List_native: Native2,
  List_banner: Native3,
  Result_inter: Inter,
  Result_native: Native2,
  Result_banner: Native3
};
